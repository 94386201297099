import React from "react";
import { Grid, Typography, Link, Card, CardMedia, Divider, Box, useMediaQuery, useTheme, IconButton } from "@mui/material";
import Og from '../media/og_image.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import { instagramUrl } from "../util/Constants";
import { Link as RouterLink} from 'react-router-dom';

const Copyright: React.FC = () => {
    const theme = useTheme();
    const isSmOrLess = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid className={'copyright'} flexDirection={'column'} container width={"100%"} flex={0} sx={{ background: 'white'  }} justifyContent={'start'} alignContent={'end'} padding={'0px 5% 0px 5%'}>
            <Grid item container flexDirection={'row'} justifyContent={'start'} padding={'32px 0px 0px 0px'}>
                <Card component={Grid} elevation={0} width={340} maxWidth={400} padding={'0px 0px 32px 0px'}>
                    <CardMedia
                        component="img"
                        image={Og}
                        alt="og image"
                        onClick={() => window.open('http://simonehomeandgarden.com/')}
                    />
                </Card>
                <Card component={Grid} container elevation={0} alignContent={'top'} justifyContent={isSmOrLess ? 'start' : 'end'} flex={1} minWidth={120}>
                    <Box width={200} padding={'00px 10px 20px 10px'} sx={{ display: 'grid', maxHeight: 100 }}>
                        <Typography color={"primary.main"} fontSize={'0.8rem'} fontWeight={500}>Simone Home and Garden</Typography>
                        <Typography color={"primary.main"} fontSize={'0.7rem'} fontWeight={300} component={RouterLink} to={'/home'}>Home</Typography>
                        <Typography color={"primary.main"} fontSize={'0.7rem'} fontWeight={300} component={RouterLink} to={'/services'}>Services</Typography>
                        <Typography color={"primary.main"} fontSize={'0.7rem'} fontWeight={300} component={RouterLink} to={'/inquiry'}>Inquiry</Typography>
                    </Box>
                </Card>
            </Grid>
            <Divider width={'100%'} variant={'fullWidth'} component={Grid} />
            <Grid item container flexDirection={isSmOrLess ? 'column-reverse' : 'row'} alignContent={"center"} justifyContent={'space-between'} padding={'24px 0px 24px 0px'}>
                <Grid item alignContent={'center'}>
                    <Typography variant={"body2"} fontWeight={200} fontSize={'0.75rem'} color={"primary.main"} align="left">
                        {'Copyright © '}
                        <Link color="inherit" href="http://simonehomeandgarden.com/">
                            Simone Home and Garden
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {'.'}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton disableFocusRipple color="primary" sx={{ marginLeft: 'auto' }} onClick={() => { window.open(instagramUrl || '', '_blank') }}>
                        <Typography variant={"body1"} fontWeight={200} paddingRight={2} fontSize={'0.8rem'} color={"primary.main"} align="left">
                            {'Discover more inspiration on my Instagram page!'}
                        </Typography>
                        <InstagramIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Copyright;