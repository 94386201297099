import * as React from 'react';
import { Box, Button, Grid, IconButton, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { phoneNumber, phoneNumberViewable } from '../util/Services';
import { Link as RouterLink} from 'react-router-dom';
import { instagramUrl } from '../util/Constants';
import InstagramIcon from '@mui/icons-material/Instagram';

const Professional: React.FC = () => {
  const theme = useTheme();
  const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid container flexDirection={'row'} justifyContent={'center'} alignItems={'center'} sx={{ paddingTop: 3, paddingBottom: 3, width: '100%', maxWidth: 700, borderRadius: '0px', background: 'transparent'}}>
      <Grid item container xs={12} md={6} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <Button variant={'contained'} color={'primary'} component={RouterLink} to={'/inquiry'}>
          {'Schedule a consultation'}
        </Button>
        <Typography fontWeight={400} fontSize={isMdOrLess ? 14 : 16} align={'center'} padding={'20px 30px 0px 30px'} variant={'h6'} color={'primary'}>
          <Link target="_blank" underline={'hover'} href={`tel:+1${phoneNumber}`}>Or call now! {phoneNumberViewable}</Link>
        </Typography>
      </Grid>
      {isMdOrLess && (
        <Box sx={{ marginTop: 3, paddingTop: 3, width: '200px', borderTop: '1px solid #2169517a' }}></Box>
      )}
      <Grid item container xs={12} md={6} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} paddingTop={2}>
        <Typography variant={"body2"} width={220} align={'center'} fontWeight={400} fontSize={'1rem'} color={"primary.main"}>
            {'Discover more inspiration on my Instagram page!'}
        </Typography>
        <IconButton disableFocusRipple color="primary" onClick={() => { window.open(instagramUrl || '', '_blank') }}>
          <InstagramIcon sx={{ fontSize: '3rem' }} />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default Professional;
