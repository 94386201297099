
import Fireplace from '../media/fireplace.jpg';
import Indoor from '../media/indoor.jpg';

import Privacy from '../media/garden/pergola.jpg';
import Design from '../media/garden/landscape_design.jpg';
import Retaining from '../media/garden/retaining.jpg';
import Mulching from '../media/garden/mulching.jpg';
import PaverPatios from '../media/garden/paver_patios.jpg';
import Trim from '../media/home/trim_mold.jpg';
import Shrubs from '../media/garden/tree_shrub.jpg';
import Flooring from '../media/home/flooring.jpg';
import Shelving from '../media/home/shelving.jpg';
import Attic from '../media/home/basement_attic.jpg';
export const phoneNumber = 7166976658;
export const phoneNumberViewable = '(716) 697-6658';

export interface ServiceType {
    name: string;
    description?: string;
    image: string;
}

export const homeServices: ServiceType[] = [
    {
        name: 'Interior Enhancements',
        //description: 'Transform your space with our Interior Modeling Service! We create stunning 3D visualizations of your home or office, letting you see and tweak every detail before any work begins. Experience your ideal design in advance and make confident decisions with our expert guidance. Let’s bring your vision to life—contact us today!',
        image: Fireplace
    },
    {
        name: 'Feature Walls',
        //description: 'We specialize in creating striking accent walls that add character and style to any room. From bold colors and unique textures to elegant designs and custom artwork, our expert team brings your vision to life. Elevate your space and create a focal point—contact us today to get started!',
        image: Indoor
    },
    {
        name: 'Flooring Installation',
        //description: 'From stylish hardwood to durable vinyl and elegant tile, we provide expert installation with impeccable precision and efficiency. Enjoy a flawless finish and a fresh new look—contact us today for a free consultation and transform your floors with ease!',
        image: Flooring
    },
    {
        name: 'Basement & Attic Finishing',
        //description: "Unlock the potential of your home! We turn underutilized spaces into beautiful, functional rooms tailored to your needs. Whether it's a cozy den, a stylish office, or a new bedroom, our expert team delivers top-quality craftsmanship and stunning results. Contact us today to start transforming your basement or attic!",
        image: Attic
    },
    {
        name: 'Molding & Trim Work',
        //description: "Elevate your interiors! We add classic charm and sophisticated detail with custom moldings, trim, and finishes. Whether you're updating your home’s elegance or adding a touch of luxury, our expert craftsmanship delivers stunning results. Transform your space with timeless appeal—contact us today!",
        image: Trim
    },
    {
        name: 'Cabinet & Shelving Installation',
        //description: "Maximize your space with custom shelving! We design and build bespoke shelving solutions that perfectly fit your needs and style. Whether it’s for your home office, living room, or closet, our expert craftsmanship ensures a functional and stylish solution. Organize in style—contact us today for a personalized design consultation!",
        image: Shelving
    }
];

/*

Garden
Landscape Design & Build
Mulching & Garden Maintenance
Tree & Shrub Planting
Retaining Walls
Deck, Patio & Fence Restoration

*/
export const gardenServices: ServiceType[] = [
    {
        name: 'Landscape Design & Build',
        //description: 'We craft custom designs tailored to your vision and execute them with precision, creating stunning, functional landscapes that enhance your property. From concept to completion, our expert team ensures every detail is perfect, delivering a beautiful and lasting outdoor environment. Contact us today for a free consultation!',
        image: Design
    },
    {
        name: 'Paver Patios',
        //description: "Transform your outdoor space with our Paver Patios Service! We design and install stunning, durable paver patios that enhance your backyard's beauty and functionality. Choose from a variety of styles and materials to create the perfect setting for entertaining or relaxing. Elevate your outdoor living experience—contact us today for a free consultation!",
        image: PaverPatios
    },
    {
        name: 'Retaining Walls',
        //description: 'Enhance your landscape with our professional retaining wall installation services! We create durable, stylish retaining walls that prevent erosion, add structural support, and elevate your outdoor design. Trust our experts to build a functional and visually appealing solution tailored to your needs. Get in touch now for a free estimate and transform your yard today!',
        image: Retaining
    },
    {
        name: 'Tree & Shrub Planting',
        //description: 'We carefully select and plant high-quality trees and shrubs to enhance your outdoor space, improve curb appeal, and boost property value. Our skilled team ensures optimal placement and care for long-lasting beauty and health. Contact us now to bring your vision to life!',
        image: Shrubs
    },
    {
        name: 'Pergolas & Privacy Fences',
        //description: "Revitalize your outdoor spaces! We can restore and refresh your deck, patio, and/or fence to their former glory, using top-notch materials and expert techniques. Enjoy a stunning, like-new look that enhances your home's curb appeal and extends the life of your outdoor investments. Contact us today for a free consultation!",
        image: Privacy
    },
    {
        name: 'Mulching & Garden Maintenance',
        //description: 'We provide mulching services to enhance soil health and appearance, while our comprehensive garden maintenance keeps your outdoor space lush and beautiful. From weeding and pruning to fertilizing, we handle it all. Contact us today!',
        image: Mulching
    }
];