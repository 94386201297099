import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { ServiceType } from '../util/Services';
import { Box } from '@mui/material';

interface ServiceCardTypeProps {
    service: ServiceType,
}

const ServiceCard: React.FC<ServiceCardTypeProps> = (props: ServiceCardTypeProps) => {
    const { service } = props;
  return (
    <Card elevation={6} sx={{ maxWidth: 345, width: '100%' }}>
      <CardMedia
        component="img"
        alt={service.name}
        height="220"
        image={`${service.image}`}
        sx={{
          borderBottom: '1px solid #d0d0d0'
        }}
      />
      <CardContent paddingBottom={'8px !important'} paddingTop={'8px !important'} component={Box}>
        <Typography variant="h5" component="div" sx={{ fontFamily: 'Lora, serif'}}>
          {service.name}
        </Typography>
        {service.description && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {service.description}
            </Typography>
          )
        }
      </CardContent>
    </Card>
  );
}

export default ServiceCard;