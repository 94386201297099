import * as React from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Patio from '../media/star_one.png';

const Professional: React.FC = () => {
  const theme = useTheme();
  const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid container minHeight={'100%'} className='home-view' flexDirection={isMdOrLess ? 'row-reverse' : 'row'}>
      <Grid container item md={12} direction={'column'}>
        <Grid container item sx={{ marginBottom: 'auto' }} justifyContent={'center'}>
          <Grid item style={{ width: '100%' }} >
            <Box className='landing-image' sx={{
              width: '100%',
              height: '400px',
              backgroundImage: `url(${Patio})`,
              backgroundPosition: 'top',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              border: 'none',
              zIndex: -2,
              maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,.9), rgba(0,0,0,0) 100%)'
            }}>
            </Box>
          </Grid>
        </Grid>
        <Grid item container style={{ width: '100%' }} marginTop={'-80px'} justifyContent={'center'} padding={'20px'}>
          <Typography letterSpacing={1} fontWeight={300} fontSize={50} align={'center'} variant={'subtitle1'} color={'primary'}>
            {'Simone Home and Garden'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container style={{ width: '100%' }} justifyContent={'center'} padding={'20px'}>
          <Typography letterSpacing={1} fontWeight={200} fontSize={18} align={'center'} padding={'0px 60px'} variant={'body2'} color={'primary'}>
            {'Transform your home and garden with my expert services, from landscaping and patio building to deck repair and interior renovations. I go beyond the basics, offering a wide range of additional services to meet all your needs. Please reach out for all of your home improvement needs!'}
          </Typography>
        </Grid>
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={Patio}
        alt="increase priority"
      />
    </Grid>
  );
}

export default Professional;
