import * as React from 'react';
import { Grid, Button, Paper, Toolbar, AppBar, Box, Drawer, IconButton, useMediaQuery, useTheme, Card, CardMedia } from '@mui/material';
import { useState } from 'react';
import { Link as RouterLink} from 'react-router-dom';
import { useMatch } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import InstagramIcon from '@mui/icons-material/Instagram';
import MainLogo from '../media/main_logo.png';
import { instagramUrl, toolbarLg, toolbarMd } from '../util/Constants';
import Og from '../media/og_image.png';

interface LinkBarPropTypes {
  copyright?: React.ReactNode;
  home?: boolean;
}
const LinkBar: React.FC<LinkBarPropTypes> = (props: LinkBarPropTypes) => {
  const { copyright } = props;
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const theme = useTheme();
  const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));
  function openInNewTab(url: string) {
    if (window && window.open) {
      window.open(url, '_blank');
    }
  }
  
  const homeButton = <Button disableFocusRipple key={1} className={`router-button ${useMatch("/home") ? "active-nav" : ""}`} variant={'text'} component={RouterLink} to={'/home'}>Home</Button>;
  const coreNavButtons = [
    <Button disableFocusRipple key={2} className={`router-button ${useMatch("/services") ? "active-nav" : ""}`} variant={'text'} component={RouterLink} to={'/services'}>Services</Button>,
    <Button disableFocusRipple key={4} className={`router-button ${useMatch("/inquiry") ? "active-nav" : ""}`} variant={'text'} component={RouterLink} to={'/inquiry'}>Inquiry</Button>
  ];
  const navButtons = [homeButton, ...coreNavButtons];
  const drawerWidth = 240;
  const container = window !== undefined ? () => document.body : undefined;
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} className={'nav-drawer'} >
      <Grid container flexDirection={'column'} padding={'48px 0px'}>
        {navButtons}
      </Grid>
    </Box>
  );
  return (
    <AppBar position={"fixed"} sx={{ background: 'white' }} elevation={4}>
      <Toolbar variant="dense" sx={{ height: isMdOrLess ? toolbarMd : toolbarLg }}>
        {isMdOrLess && 
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        }
        <IconButton color="inherit">
            <img width={200} src={MainLogo} alt="simone_home_and_garden" />
        </IconButton>
        {!isMdOrLess &&  <Grid container marginLeft={'-150px'} justifyContent={'center'}>{navButtons}</Grid>}
        <IconButton size={'large'} color="primary" sx={{ marginLeft: 'auto' }} onClick={() => { window.open(instagramUrl || '', '_blank') }}><InstagramIcon sx={{ fontSize: '2rem' }}/></IconButton>
      </Toolbar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: drawerWidth, backgroundColor: 'transparent' }} elevation={0}>
            <Card component={Grid} elevation={0} width={240} maxWidth={400}>
              <CardMedia
                  component="img"
                  image={Og}
                  alt="og image"
                  onClick={() => window.open('http://simonehomeandgarden.com/')}
              />
            </Card>
          </Paper>
        </Drawer>
      </nav>
    </AppBar>
  );
}

export default LinkBar;