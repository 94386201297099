import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';


export const createModeTheme = (mode: string) => {
  return createTheme({
    palette: {
      mode: mode as PaletteMode,
      primary: {
        main: '#003e29',
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: '#cce7cd',
        contrastText: 'black',
        // light: '#F5EBFF',
        // dark: will be calculated from palette.secondary.main,
        // contrastText: '#47008F',
      }
    }
  });
};
