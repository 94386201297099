import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createModeTheme } from './theme';
import { Grid, useTheme } from '@mui/material';
import Home from './pages/Home';
import "./App.scss"
import LinkBar from './components/LinkBar';
import { toolbarMd, toolbarLg } from './util/Constants';
import Inquiry from './pages/Inquiry';
import Professional from './pages/Professional';
import Services from './pages/Services';
import Copyright from './components/Copyright';

const App: React.FC = () => {
  const theme = useTheme();
  const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode] = React.useState<'light' | 'dark'>('light');
  const updatedTheme = createModeTheme(mode);
  function ContentInRouter() {
    return (
      <React.Fragment>
        <Grid container alignContent={"space-between"} height={"100%"} width={"100%"}>
          <Grid item container maxHeight={'100%'} paddingBottom={isMdOrLess ? toolbarMd : 0}>
            <Grid item flexDirection={'row'}>
              <LinkBar home={false} copyright={<Copyright />} />
            </Grid>
            <Grid item container maxHeight={`calc(100% - ${isMdOrLess ? toolbarMd : toolbarLg})`} className={'main-view'} marginTop={isMdOrLess ? toolbarMd : toolbarLg} >
              <Grid item spacing={2} flex={1} maxHeight={'100%'} className={'scroll-view'}>
                <Grid item flex={1} height={'100%'} className={'app-view'}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/professional" element={<Professional />} />
                    <Route path="/inquiry" element={<Inquiry />} />
                  </Routes>
                  {<Copyright />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  return (
    <ThemeProvider theme={updatedTheme}>
      <CssBaseline />
      <Router>
        <Grid container alignContent={"space-between"} height={"100%"} width={"100%"}>
          <ContentInRouter />
        </Grid>
      </Router>
    </ThemeProvider>
  );
}

export default App;